import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {map} from 'rxjs/operators';

import { Score } from './models/Score';

@Injectable({
  providedIn: 'root'
})
export class HighscoreService {

  baseUrl = 'https://highscore-game-official.com/api';

  constructor(private http: HttpClient) {  }

  getAll(type: string) {
    return this.http.post(`${this.baseUrl}/list`, {data: type}).pipe(
      map((res: any) => {
        return res['data'];
      })
    );
  }
}
