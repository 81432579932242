<div class="container text-white text-center">
  <div> <img src=" ../assets/sprites/LOGO.png " class="mt-4 mx-auto d-block img-fluid" alt=" " width="512" height="128">
  </div>
  <div>
    <a href="https://store.steampowered.com/app/2136550/HIGhSCORE/">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256"
        width="50px" height="50px" fill-rule="nonzero">
        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
          stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"
          font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
          <g transform="scale(5.12,5.12)">
            <path
              d="M12,3c-4.96,0 -9,4.04 -9,9v13.39063l13.44922,6.71875c0.49,0.24 0.69141,0.83984 0.44141,1.33984c-0.17,0.35 -0.52062,0.55078 -0.89062,0.55078c-0.15,0 -0.29922,-0.02938 -0.44922,-0.10937l-12.55078,-6.27148v10.38086c0,4.96 4.04,9 9,9h26c4.96,0 9,-4.04 9,-9v-26c0,-4.96 -4.04,-9 -9,-9zM31.5,9c5.24,0 9.5,4.26 9.5,9.5c0,5.24 -4.26,9.5 -9.5,9.5c-1.1,0 -2.15992,-0.18078 -3.16992,-0.55078l-4.75,3.58008c0.28,0.79 0.41992,1.6207 0.41992,2.4707c0,4.14 -3.36,7.5 -7.5,7.5c-4.14,0 -7.5,-3.36 -7.5,-7.5c0,-0.22 0.00953,-0.43062 0.01953,-0.64062l5.64062,2.82031c0.42,0.21 0.87984,0.32031 1.33984,0.32031c1.14,0 2.16969,-0.64016 2.67969,-1.66016c0.74,-1.48 0.14016,-3.27953 -1.33984,-4.01953l-5.44922,-2.73047c1.27,-1 2.86938,-1.58984 4.60938,-1.58984c0.9,0 1.78914,0.1607 2.61914,0.4707l3.45117,-4.7207c-0.38,-1.04 -0.57031,-2.13 -0.57031,-3.25c0,-5.24 4.26,-9.5 9.5,-9.5zM31.5,13c-3.02572,0 -5.5,2.47428 -5.5,5.5c0,3.02572 2.47428,5.5 5.5,5.5c3.02572,0 5.5,-2.47428 5.5,-5.5c0,-3.02572 -2.47428,-5.5 -5.5,-5.5zM31.5,15c1.94484,0 3.5,1.55516 3.5,3.5c0,1.94484 -1.55516,3.5 -3.5,3.5c-1.94484,0 -3.5,-1.55516 -3.5,-3.5c0,-1.94484 1.55516,-3.5 3.5,-3.5z">
            </path>
          </g>
        </g>
      </svg>
      <p class="mt-2">DOWNLOAD ON STEAM</p>
    </a>
  </div>
  <hr>

  <h2 class="mb-3"><img src="../assets/sprites/Player_Head.png" class="rotate" alt="" width="32" height="32" /> REACH THE SKY <img
      src="../assets/sprites/Player_Head.png" class="rotate" alt="" width="32" height="32" /></h2>
  <p>On the eternal hunt for the highscore you will <span class="text-yellow">unlock new items and cosmetics</span>!</p>
  <p>Level up your character and <span class="text-yellow">unlock new abilities</span>!</p>
  <p>Will you <span class="text-yellow">break the record</span> and be on the top of the rankings?</p>
  <hr>
  <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../assets/sprites/Carousel_4.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_6.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_7.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_5.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_2.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_3.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_8.png" class="d-block w-100 rounded" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../assets/sprites/Carousel_9.png" class="d-block w-100 rounded" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <hr>
  <h2 class="mb-3"><img src="../assets/sprites/Player_Head.png" class="rotate" alt="" width="32" height="32" /> FEATURES <img
    src="../assets/sprites/Player_Head.png" class="rotate" alt="" width="32" height="32" /></h2>
  <table class="table table-borderless" style="table-layout: fixed; margin-left:-5%">
    <tbody class="text-white">
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Increase your score</span></p>
        </td>
        <td style="text-align: left">
          <p> Level up and buy items to push further!</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Customize your character</span></p>
        </td>
        <td style="text-align: left">
          <p> Personalize your character from a range of
            hats and colors!
          </p>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Unlock new items</span></p>
        </td>
        <td style="text-align: left">
          <p> Buy new items that will boost your score!</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Runes</span></p>
        </td>
        <td style="text-align: left">
          <p> Use runes to start with preferred items!</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Global leaderboard</span></p>
        </td>
        <td style="text-align: left">
          <p> Compete with others and try to be number one!</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Medals</span></p>
        </td>
        <td style="text-align: left">
          <p> Earn medals for the best runs!</p>
        </td>
      </tr>
      <tr>
        <td style="text-align: right">
          <p><span class="text-yellow">Achievements</span></p>
        </td>
        <td style="text-align: left">
          <p> Reach milestones to prove your skills to the world!</p>
        </td>
      </tr>
    </tbody>
  </table>
  <hr>
</div>