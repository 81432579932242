import { Component, OnInit } from '@angular/core';
import { HighscoreService } from '../highscore.service';
import { Score } from '../models/Score';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.css']
})
export class LeaderBoardComponent implements OnInit {
  scores: Score[] = [];
  error = '';
  success = '';
  date = new Date();
  dateText: string;

  constructor(private scoreService: HighscoreService, private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.dateText = 'All Time';
    this.getScores('All Time');
  }

  getScores(type: string): void {

    if (type === 'Daily') {
      this.dateText = `Daily - ${this.datePipe.transform((new Date), 'EEEE, MMMM d, y')}`;
    }
    else if (type === 'Weekly') {
      this.dateText = `Weekly - CW ${this.datePipe.transform((new Date), 'w')}, ${this.datePipe.transform((new Date), 'yyyy')}`;
    }
    else if (type === 'Monthly') {
      this.dateText = `Monthly - ${this.datePipe.transform((new Date), 'MMMM yyyy')}`;
    }
    else if (type === 'Yearly') {
      this.dateText = `Yearly - ${this.datePipe.transform((new Date), 'yyyy')}`;
    }
    else if (type === 'All Time') {
      this.dateText = 'All Time';
    }
    this.resetAlerts();

    this.scoreService.getAll(type).subscribe(
      (data: Score[]) => {
        this.scores = data;
        this.success = 'successful retrieval of the list';
      },
      (err) => {
        console.log(err);
        this.error = err;
      }
    );
  }
  
  resetAlerts() {
    this.error = '';
    this.success = '';
  }

  getStyle(score: string)
  {
    var length = score.toString().length;

    if (length <= 6)
    {
        return "#FFFFFF";
    }
    else if (length <= 12)
    {
        return "#FAC300";
    }
    else if (length <= 18)
    {
        return "#F06A0C";
    }
    else if (length <= 21)
    {
        return "#D93A00";
    }
    else
    {
        return "#E60B1E";
    }
  }
}
