<div class="container text-white text-center">
  <div class="btn-group my-5" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-dark" (click)="getScores('Daily')">Daily</button>
    <button type="button" class="btn btn-dark" (click)="getScores('Weekly')">Weekly</button>
    <button type="button" class="btn btn-dark" (click)="getScores('Monthly')">Monthly</button>
    <button type="button" class="btn btn-dark" (click)="getScores('Yearly')">Yearly</button>
    <button type="button" class="btn btn-dark" (click)="getScores('All Time')">All Time</button>
  </div>
  <h2 class="my-3">{{dateText}}</h2>
  <div class="table-responsive">
    <table *ngIf="scores" class="table mb-5">
      <thead>
        <tr>
          <th width="10%" scope="col">Rank</th>
          <th width="20%" scope="col">Player</th>
          <th width="20%" scope="col">Level</th>
          <th width="20%" scope="col">Score</th>
          <th width="30%" scope="col">Time</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr>
        <th scope="row">#</th>
        <td>TEST-ROW #1</td>
        <td>250</td>
        <td  style="text-align: right;"[style.color]="getStyle('199999999999999999999999')">1,999,999,999,999,999,999,999,999</td>
        <td>13:37</td>
      </tr>
      <tr>
        <th scope="row">#</th>
        <td>TEST-ROW #2</td>
        <td>250</td>
        <td style="text-align: right;" [style.color]="getStyle('1999999999999999999')">1,999,999,999,999,999,999</td>
        <td>13:37</td>
      </tr> -->
        <tr *ngFor="let score of scores; let i = index">
          <th scope="row">{{i+1}}</th>
          <td>{{score.name}}</td>
          <td>{{score.level}}</td>
          <td style="text-align: right;" [style.color]="getStyle(score.score)">{{score.score | number}}</td>
          <td>{{score.time}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>