<nav class="navbar navbar-expand-lg navbar-dark bg-purple">
    <div class="container-fluid">
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-0">
                <li class="nav-item">
                    <a class="nav-link text-center active" aria-current="page" routerLink="/home">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center active" aria-current="page"
                        routerLink="/leaderboards">Leaderboards</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center active" href="mailto:info@highscore-game-official.com" aria-current="page">Contact</a>
                </li>
            </ul>
        </div>
    </div>
</nav>